import React, { ReactNode, useEffect, useState } from 'react'
import styled, { AnyStyledComponent, css } from 'styled-components'
import { Modal } from '../Modals/Modal'
import { getListIndicator, ListType } from '@src/utils'
import { Token } from '@revolut/ui-kit'
import { defaultTheme } from '@src/styles/theme'

export type placement = 'top' | 'top-start' | 'bottom' | 'left' | 'right'

interface TooltipPopoverProps {
  noArrow?: boolean
  placement: placement
  body?: boolean
  backgroundColor?: string
}

export const TooltipPopover = styled.div<TooltipPopoverProps>`
  opacity: inherit;
  pointer-events: inherit;
  display: grid;
  grid-gap: 8px;
  position: relative;
  white-space: normal;
  font-size: 12px;
  transition: opacity 150ms ease-in-out;
  border-radius: 5px;
  background-color: ${props => props.backgroundColor ?? Token.color.foreground};
  color: ${Token.color.background};

  &:after {
    content: ' ';
    position: absolute;
    ${props => {
      if (props.noArrow) {
        return ''
      }
      switch (props.placement) {
        case 'right':
          return css`
            top: 50%;
            right: 100%;
            margin-top: -7px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 7px solid ${props.backgroundColor ?? Token.color.foreground};
          `
        case 'bottom':
          return css`
            left: 50%;
            bottom: 100%;
            margin-left: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid ${props.backgroundColor ?? Token.color.foreground};
          `
        case 'left':
          return css`
            left: 100%;
            top: 50%;
            margin-top: -7px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 7px solid ${props.backgroundColor ?? Token.color.foreground};
          `
        case 'top':
        case 'top-start':
          return css`
            left: 50%;
            top: 100%;
            margin-left: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid ${props.backgroundColor ?? Token.color.foreground};
          `
        default:
          return ''
      }
    }};
    width: 0;
    height: 0;
  }
`

export const TooltipContainer = styled.div<TooltipPopoverProps>`
  position: absolute;
  display: flex;
  align-items: center;
  width: ${props => (props.body ? 'auto' : '300px')};
  pointer-events: none;
  opacity: 0;
  z-index: ${defaultTheme.zIndex.tooltip};
  ${props => {
    switch (props.placement) {
      case 'right':
        return css`
          top: 50%;
          transform: translateY(-50%);
          left: 100%;
        `
      case 'top':
        return css`
          justify-content: center;
          bottom: 100%;
          transform: translateX(-50%);
          left: 50%;
        `
      case 'top-start':
        return css`
          justify-content: flex-start;
          bottom: 100%;
        `
      case 'left':
        return css`
          top: 50%;
          justify-content: flex-end;
          transform: translateY(-50%);
          right: 100%;
        `
      case 'bottom':
        return css`
          justify-content: center;
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
        `
      default:
        return ''
    }
  }};
  overflow: visible;
`

export const HoverableEmptySpace = styled.div<TooltipPopoverProps>`
  --padding: 10px;
  ${props => {
    switch (props.placement) {
      case 'right':
        return css`
          padding-left: var(--padding);
        `
      case 'top':
      case 'top-start':
        return css`
          padding-bottom: var(--padding);
        `
      case 'left':
        return css`
          padding-right: var(--padding);
        `
      default:
        return css`
          padding-top: var(--padding);
        `
    }
  }}
`

const fullWidthCss = css`
  flex-direction: column;
  align-items: unset;
`

const ContainerCss = css<{ justifyContent: string; fullWidth?: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? `${props.justifyContent}` : 'center'};
  align-items: center;
  position: relative;

  ${({ fullWidth }) => fullWidth && fullWidthCss}
  &:hover ${TooltipContainer} {
    opacity: 1;
    pointer-events: all;
  }
`

const Container = styled.div`
  ${ContainerCss};
`

const ContainerSVG = styled.g`
  ${ContainerCss};
`

const Text = styled.div<{ useFormatting: boolean }>`
  padding: 16px;
  white-space: ${props => (props.useFormatting ? 'pre-line' : 'inherit')};
`

const TooltipTitle = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  line-height: 12px;
  color: ${Token.color.greyTone50};
`

export const AnchorCopy = styled.div<{ position: DOMRect }>`
  pointer-events: none;
  position: fixed;
  width: ${props => props.position.width}px;
  height: ${props => props.position.height}px;
  top: ${props => props.position.top}px;
  left: ${props => props.position.left}px;
  z-index: ${defaultTheme.zIndex.popup};

  & > ${TooltipContainer} {
    opacity: 1;
    pointer-events: all;
  }
`

const TooltipText = styled.div<{ flex?: boolean }>`
  font-size: 13px;
  line-height: 18px;
  color: ${Token.color.greyTone2};
  display: ${props => (props.flex ? 'flex' : 'inherit')};
`

const TooltipTextLetter = styled.span`
  padding-right: 8px;
`

const TooltipTextWrap = styled.div<{ isPadding?: boolean }>`
  padding-top: ${props => (props.isPadding ? '8px' : 0)};
`

interface TooltipInterface {
  /** On children component hover the tooltip appear */
  children?: any
  /** Title inside the tooltip */
  title?: string
  /** Text of the tooltip */
  text?: string | null
  /** Flag to hide the arrow pointing to the children */
  noArrow?: boolean
  /** Body to overwrite the title and text with your own component */
  body?: ReactNode
  /** Placemen of the tooltip in relation to the anchor (default: children) */
  placement: placement
  justifyContent?: string
  className?: string
  /** Flag for Tooltip for svg elements */
  isSvg?: boolean
  /** Event to trigger when hover on children */
  onHover?: () => void
  /** Event to trigger when mouse leaves the tooltip */
  onLeave?: () => void
  /** Forcefully hide the tooltip */
  hide?: boolean
  /** Reference component to attach tooltip to, Default: children */
  anchor?: HTMLElement
  /** Type of text list (letters - a,b,c.. letters will be added) */
  listStyle?: 'letters'
  /** override color of tooltip */
  backgroundColor?: string
  /** apply formatting */
  useFormatting?: boolean
  /** Force show the tooltip until hovered */
  forceShow?: boolean
  /** change default delay of the toolip, in ms */
  delay?: number
  /** stretch the content to the full width */
  fullWidth?: boolean
}

export const TOOLTIP_DELAY = 250
let timeout: NodeJS.Timeout

function Tooltip({
  className,
  placement,
  justifyContent,
  title,
  anchor,
  text,
  forceShow,
  body,
  children,
  isSvg,
  noArrow,
  hide,
  backgroundColor,
  listStyle,
  onHover,
  onLeave,
  useFormatting = false,
  delay = TOOLTIP_DELAY,
  fullWidth,
}: TooltipInterface) {
  const [hovered, setHovered] = useState(false)
  const [show, setShow] = useState(forceShow || false)
  const [position, setPosition] = useState<DOMRect | null>(null)
  const Cont: AnyStyledComponent = isSvg ? ContainerSVG : Container

  useEffect(() => {
    if (hovered) {
      timeout = setTimeout(() => {
        setShow(true)
      }, delay)
    } else {
      setShow(false)
      clearTimeout(timeout)
    }
  }, [hovered])

  useEffect(() => {
    if (forceShow && anchor) {
      const contentRect = anchor.getBoundingClientRect()
      setPosition(contentRect)
      setHovered(true)
    }
  }, [forceShow])

  useEffect(() => {
    if (hide) {
      setHovered(false)
    }
  }, [hide])

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    if (hide) {
      return
    }
    const contentRect = (
      anchor || (e.currentTarget as HTMLDivElement)
    ).getBoundingClientRect()
    setPosition(contentRect)
    setHovered(true)
    onHover?.()
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!hovered && !hide) {
      const contentRect = (
        anchor || (e.currentTarget as HTMLDivElement)
      ).getBoundingClientRect()
      setPosition(contentRect)
      setHovered(true)
    }
  }

  const handleMouseLeave = () => {
    setHovered(false)
    onLeave?.()
  }

  if (!text && !body) {
    return children
  }

  return (
    <Cont
      className={className}
      justifyContent={justifyContent}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onPointerLeave={handleMouseLeave} // disabled input elements don't work with onMouseLeave well
      onMouseMove={handleMouseMove}
      onWheel={handleMouseLeave}
      data-testid="tooltip"
      fullWidth={fullWidth}
    >
      {!hide && position && show && (
        <Modal>
          <AnchorCopy position={position}>
            <TooltipContainer placement={placement} body={!!body}>
              <HoverableEmptySpace placement={placement}>
                <TooltipPopover
                  noArrow={noArrow}
                  placement={placement}
                  onMouseLeave={handleMouseLeave}
                  backgroundColor={backgroundColor}
                >
                  {body || (
                    <Text useFormatting={useFormatting}>
                      {title && <TooltipTitle>{title}</TooltipTitle>}
                      {text && (
                        <TooltipTextWrap isPadding={!!title}>
                          {text.split('\\n').map((t, i) =>
                            listStyle === 'letters' ? (
                              <TooltipText key={i} flex>
                                <TooltipTextLetter>
                                  {getListIndicator(i, ListType.letters)}
                                </TooltipTextLetter>
                                {t}
                              </TooltipText>
                            ) : (
                              <TooltipText key={i}>{t}</TooltipText>
                            ),
                          )}
                        </TooltipTextWrap>
                      )}
                    </Text>
                  )}
                </TooltipPopover>
              </HoverableEmptySpace>
            </TooltipContainer>
          </AnchorCopy>
        </Modal>
      )}
      {children}
    </Cont>
  )
}

export default Tooltip
